export const title = 'Usługi poligraficzne - druk cyfrowy, offsetowy, rolowy';
export const description = 'Baner Bunny to drukarnia w której oferujemy druk lateksowy oraz solwentowy. Z nami wydrukujesz ulotki oraz wizytówki. Proponujemy również uszlachetnienia takie jak foliowanie i lakierowanie UV.';
export const mainHeader = 'Druk Wielkoformatowy';

export const content = [
  {
    id: 'druk-solwentowy',
    article: `## Druk <span class="orange">solwentowy</span>
    \n Czyli tak zwany solwent, technika druku wykonywana na wielkoformatowych ploterach atramentowych z możliwością zadruku nieograniczonej powierzchni. W zależności od zadrukowanego nośnika wykorzystywane są plotery o różnym przeznaczeniu oraz tusze o innych właściwościach.\n * Plotery drukujące o szerokości do 5 metrów z tuszami hard solvent.
    Druk rolowy z wykorzystaniem dostępnych najtrwalszych tuszów z spośród wszystkich atramentów solwentowych. Jego trwałość szacuje się na ok. 3-7 lat. Wydruk przystosowany jest do warunków zewnętrznych i promieniowanie UV. Rozdzielczość druku wynosi od 360 do 720 dpi i jest uzależniona od wielkości ekspozycji i grafiki. Metoda ta ze względu na wykorzystanie silnych rozpuszczalników przeznaczona jest do jedynie do ekspozycji zewnętrznej.\n
    **Zastosowanie:** Banery i siatki mesh wykorzystywane do ekspozycji zewnętrznej.\n * Plotery UV stołowe o powierzchni 3x2m.
    Druk płaski z wykorzystaniem tuszy polimerowych, które utrwalane są światłem UV przez co wydruk nabiera dużej odporności oraz ekspresowo wysycha i jest gotowy do dalszego wykończenia. Dodatkowo w odróżnieniu od tradycyjnych ploterów solwentowych występuje możliwość nadruku biały kolorem.\n
    **Zastosowanie:** Płyty PCV, szkło, płyty drewniane.\n * Plotery drukująco-tnące o szerokości do 1,6 metra z tuszami mild solvent.
    Druk rolowy z wykorzystanie łagodniejszych tuszów, co za tym idzie mniej trwałych oraz mniej odpornych na ścieranie czy zarysowania. Dodatkowa możliwość nacinania wydruków po obrysie o dowolnym kształcie.  Przeznaczenie do ekspozycji wewnętrznej, krótkookresowej zewnętrznej lub długookresowej po dodatkowym zabezpieczeniu wydruku laminatem.\n
    **Zastosowanie:** Roll-up’y, systemy wystawiennicze, obrazy, folie, plakaty.    
    `,
  },
  {
    id: 'druk-lateksowy',
    article: `## Druk <span class="orange">lateksowy</span>
    \n Nazywany następcą druku solwentowego, również wykonywany na ploterach wielkoformatowy. Wydruk ma doskonale nasycone i jednolite kolory, a główną zaletą jest zdolność do szybkiego schnięcia. Używany tusz jest na bazie wody, dzięki czemu wydruk jest bezwonny i nietoksyczny.
    \n Brak niebezpiecznych chemikaliów.
    \n W przeciwieństwie do tuszy twardosolwentowych tusze z generacji lateksowej nie zawierają toksycznych chemikaliów, dzięki czemu są one w pełni bezpieczne, przyjazne środowisku, ponadto lateks znajduje się na wierzchu powierzchni druku, co ułatwia recykling.  Wydrukowane materiały z powodzeniem mogą być eksponowane w placówkach służby zdrowia takich jak szpitale, centra zdrowia czy przychodnie oraz polecane są dla różnego rodzajów restauracji, knajp, kawiarni i wszystkich innych miejsc gdzie wydruk musi być w 100% bezzapachowy.
    \n Trwałość atramentów lateksowych jest zależna od kilku znaczących czynników w tym od rodzaju użytego nośnika i intensywności światła UV. Wytrzymałość wydruku i jest podobna do wydruków tuszami mild solvent.
    \n Dzięki swoim właściwością druk lateksowy używamy głównie do druku fototapety i obrazów.
    `,
  },
  {
    id: 'druk-cyfrowy-i-offsetowy',
    article: `## Druk <span class="orange">cyfrowy</span> i <span class="orange">offsetowy</span>
    \n Druk cyfrowy wykonywany na mniejszych produkcyjnych maszynach drukujących na papierach powlekanych kredowych, niepowlekanych w tym papiery ekologiczne recyklingowe, papierach ozdobnych gładkich i tłoczonych w kolorach białych i barwionych oraz samoprzylepnych.
    \n Metoda druku stosowana i bardziej ekonomiczna przy zapotrzebowaniu na mniejsze nakłady lub w przypadku gdy wymagana jest personalizacja wydruku bądź występuje wiele wzorów projektu.
    \n Druk offsetowy to optymalny wybór, gdy potrzebujemy wydruków w większych nakładach. Zapewnia on również najdokładniejsze odwzorowanie kolorów oraz wyraźny, czysty i doskonale wyglądający wydruk.
    \n **Możliwe wydruki cyfrowe i offsetowe:**
    \n * ulotek i plakatów do max. formatu 650x330mm przy druku cyfrowym i 680x980mm przy druku offsetowym,
    \n * wizytówki na różnego rodzaju papierach i z bogatą ofertą uszlachetnienia, kaszerowane / łączone z kilku papierów, składane czy zaokrąglone.
    \n * katalogi szyte, klejone i spiralowane,
    \n * książki w oprawie twardej i miękkiej,
    \n * broszury składane, notesy, papiery firmowe, teczki, kolorowanki,
    \n * zaproszenia, vouchery, pocztówki,
    \n * metki, zawieszki, owijki papierowe na opakowania,
    \n * naklejki na papierze samoprzylepnym z możliwością wycięcia w dowolnym kształcie.
    `,
  },
  {
    id: 'introligatornia-i-uszlachetnienia',
    article: `## Introligatornia i <span class="orange">uszlachetnienia</span>
    \n Każda wydrukowana praca wymaga odpowiedniego wykończenia, zakres ten obejmuje od docięcia pod wybrany format czy bigowania lub perforacji arkuszy, po skomplikowane rodzaje szycia lub oprawę katalogów i książek, uszlachetniania papierów poprzez foliowanie, lakierowanie wybiórcze czy złocenie (hotstamping).
    \n **Możliwe uszlachetnienie wydruków:**
    \n * **Foliowanie matowe lub błyszczące** - proces jedno lub dwustronnego pokrycie wydruku folią matową lub błyszczącą, który oprócz uszlachetnienia podłoża dodatkowo je zabezpieczy przed ścieraniem, zarysowaniami oraz wzmocni jego odporności na rozdarcia.
    \n * **Foliowanie "Soft Touch"** - folia typu “Soft Touch” jest to folia matowa, która jest wyjątkowo aksamitna w dotyku. Polecana do wzbogacenia papieru na okładkę katalogu.
    \n * **Lakierowanie UV wybiórczo** - specjalistyczny lakier nałożony na powierzchnię papieru nadający efekt uwypuklenia. Lakier UV wydobędzie ze zdjęć produktów lub z ilustracji w Twoim katalogu jeszcze więcej atrakcyjności. 
    \n * **Hot stamping (złocenie i srebrzenie)** - nadaj okładce niezwykłego prestiżu i elegancji poprzez trwałe pokrycie folią złotą lub srebrną wybranych elementów.
    `,
  },
];
