/* Plugin */
import React from 'react';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

/* Components */
import {
  SEO, Container, Heading, Section, Article,
} from '../components/components';

/* Messages */
import * as messages from '../messages/drukarnia.messages';

const Drukarnia = () => (
  <>
    <SEO title={messages.title} description={messages.description} />
    <Container subPage>
      <Heading headingLevel="h1" title={messages.mainHeader} centered />
      {messages.content.map(({ id, article }) => (
        <Section id={id} key={article}>
          <Article>
            <Markdown rehypePlugins={[rehypeRaw]} children={article} />
          </Article>
        </Section>
      ))}
    </Container>
  </>
);

export default Drukarnia;
